<template>
  <div>
    <header
      class="h-12 px-4 bg-gray-50 border-b-2 border-black justify-between items-center flex"
    >
      <router-link :to="decideURL" class="font-bold"
        ><img src="@/assets/logo.png" class="w-32 h-auto"
      /></router-link>

      <button class="md:hidden" v-on:click="toggleMobileMenu">
        <svg
          v-if="!mobileMenuOpen"
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <nav
        v-if="mobileMenuOpen"
        class="inset-0 top-12 fixed bg-white/50 flex flex-col z-20 justify-center md:hidden"
      >
        <!-- <button
          v-on:click="scrollToPredictions"
          data-ms-membership="6188f7f03a942b000480956e"
          class="flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline border-l-2 border-black"
        >
          Matches <Arrow class="ml-1" />
        </button> -->
        <router-link
          to="/why"
          class="bg-white flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline border-t-2 border-b-2 border-black"
          >How it works <Arrow class="ml-1" />
        </router-link>
        <router-link
          to="/blog"
          class="bg-white flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline border-b-2 border-black"
          >Blog <Arrow class="ml-1" />
        </router-link>
        <button
          id="loginbutton"
          v-if="!subscriptionType"
          v-on:click="toggleLoginModal"
          class="bg-white flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline border-b-2 border-black"
        >
          Login <Arrow class="ml-1" />
        </button>
        <button
          v-if="!subscriptionType"
          v-on:click="handleSignUp"
          data-ms-membership="6188f7f03a942b000480956e"
          class="flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-white bg-blue-700 underline border-blue-700"
        >
          Signup <Arrow class="ml-1" />
        </button>
        <template v-else>
          <button
            v-on:click="manageMembership"
            data-ms-modal="profile"
            class="flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg bg-white text-blue-700 underline md:border-l-2 border-b-2 border-black"
          >
            <template v-if="!processing">
              <template v-if="subscriptionType == 'inactive'"
                >Upgrade Membership
              </template>
              <template v-else>Manage Membership </template>
              <Arrow class="ml-1"/></template
            ><span v-else>One moment...</span>
          </button>
          <button
            v-on:click="handleLogout"
            data-ms-modal="profile"
            class="border-b-2 flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg bg-white text-blue-700 underline md:border-l-2  border-black"
          >
            <template v-if="loggingOut">Logging out...</template
            ><template v-else>LogOut <Arrow class="ml-1"/></template>
          </button>
        </template>
      </nav>
      <nav class="hidden md:flex h-12 divide-x-2 divide-black -mr-4">
        <!-- <button
          v-on:click="scrollToPredictions"
          data-ms-membership="6188f7f03a942b000480956e"
          class="flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline border-l-2 border-black"
        >
          Matches <Arrow class="ml-1" />
        </button> -->
        <router-link
          to="/why"
          class="flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline border-l-2 border-black"
          >How it works <Arrow class="ml-1" />
        </router-link>
        <router-link
          to="/blog"
          class="flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline"
          >Blog <Arrow class="ml-1" />
        </router-link>
        <button
          id="loginbutton"
          v-if="!subscriptionType"
          v-on:click="toggleLoginModal"
          class="flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline border-l-2 border-black"
        >
          Login <Arrow class="ml-1" />
        </button>
        <button
          v-if="!subscriptionType"
          v-on:click="handleSignUp"
          data-ms-membership="6188f7f03a942b000480956e"
          class="flex items-center border-b justify-center h-12 px-4 lg:px-8 uppercase text-lg text-white bg-blue-700 underline border-l-2 border-black"
        >
          Signup <Arrow class="ml-1" />
        </button>
        <template v-else>
          <button
            v-on:click="manageMembership"
            data-ms-modal="profile"
            class="bg-white flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline border-l-2 border-black md:border-b-2 md:mt-px"
          >
            <template v-if="!processing">
              <template v-if="subscriptionType == 'inactive'"
                >Upgrade Membership
              </template>
              <template v-else>Manage Membership </template>
              <Arrow class="ml-1"/></template
            ><span v-else>One moment...</span>
          </button>
          <button
            v-on:click="handleLogout"
            data-ms-modal="profile"
            class="bg-white flex items-center justify-center h-12 px-4 lg:px-8 uppercase text-lg text-blue-700 underline border-l-2 border-black md:border-b-2 md:mt-px"
          >
            <template v-if="loggingOut">Logging out...</template
            ><template v-else>LogOut <Arrow class="ml-1"/></template>
          </button>
        </template>
      </nav>
    </header>

    <div
      class="fixed w-screen h-screen bg-black bg-opacity-70 inset-0 z-20 flex items-center justify-center"
      v-if="loginModalOpen"
      v-on:click.self="toggleLoginModal"
    >
      <div class=" bg-white w-full max-w-lg">
        <div class="text-center py-6 bg-gray-200">
          <img src="@/assets/logo.png" class="w-64 inline-block h-auto" />
          <h2 class="mt-4 mb-0">Welcome back</h2>
        </div>
        <form class="space-y-4 text-left p-6">
          <label class="block">
            <span class="block mb-2">email address</span>
            <input
              class="border border-black p-4 w-full"
              type="email"
              v-model="email"
            />
          </label>

          <label class="block">
            <span class="block mb-2">password</span>
            <input
              class="border border-black p-4 w-full"
              type="password"
              v-model="password"
            />
          </label>

          <p class="text-sm my-4 text-red-600 text-left" v-if="error">
            {{ error }}
          </p>

          <div class="flex justify-between items-center text-sm text-gray-500">
            <span></span>
            <router-link to="/reset">Reset my password</router-link>
          </div>
          <div class="text-center">
            <span v-if="!submitting">
              <button
                type="submit"
                v-on:click="testLogin"
                class="bg-gray-300 border  mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative"
              >
                Login
                <div
                  class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                ></div></button
            ></span>
            <span v-else>
              <button
                class="bg-gray-300 border  mx-auto border-black px-4 py-3 inline-block underline text-blue-700 uppercase relative opacity-50 cursor-not-allowed pointer-events-none"
              >
                Logging in
                <div
                  class="h-1 -bottom-1 absolute left-1 right-1 bg-black bg-opacity-50"
                ></div>
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "../assets/icons/Arrow";
import API from "./../api";

export default {
  name: "Header",
  components: {
    Arrow,
  },
  data() {
    return {
      mobileMenuOpen: false,
      loginModalOpen: false,
      email: "",
      password: "",
      submitting: false,
      error: false,
      processing: false,
      loggingOut: false,
    };
  },
  mounted() {},
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      this.$emit("toggleMobileMenu", this.mobileMenuOpen);
      if (this.mobileMenuOpen) {
        document.body.classList.add("h-screen");
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("h-screen");
        document.body.classList.remove("overflow-hidden");
      }
    },
    toggleLoginModal() {
      this.loginModalOpen = !this.loginModalOpen;
    },
    handleSignUp() {
      this.$router.push("/signup/monthly");
    },
    manageMembership() {
      if (this.subscriptionType == "inactive") {
        this.$router.push("/signup/monthly");
      } else {
        this.processing = true;
        this.manageExistingMembership();
      }
    },
    scrollToPredictions() {
      this.toggleMobileMenu();
      this.$router.push("/#matches");
      setTimeout(() => {
        window.donotscroll = true;
        this.$router.push("/predictions");
      }, 500);
    },
    async manageExistingMembership() {
      let data = {
        priceId: this.stripeProductCode,
        customerId: this.$store.getters.customerID,
      };
      API.post("/user/stripe_customer_portal", false, data)
        .then((response) => {
          // eslint-disable-next-line no-undef
          gtag("event", "begin_checkout", {
            event_callback: function() {
              window.location = response.data.url;
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    testLogin(event) {
      event.preventDefault();
      if (this.email && this.password) {
        this.submitLogIn(event);
      }
    },
    async submitLogIn(event) {
      this.error = false;
      this.submitting = true;
      event.preventDefault();
      let data = {
        email: this.email,
        password: this.password,
      };

      API.post("/user/login", false, data)
        .then((response) => {
          this.$store.commit("storeAccessTokens", response.data);

          let auth = {
            idToken: this.$store.getters.accessTokens.id_token,
          };
          API.get("/user", auth)
            .then((response) => {
              this.$store.commit(
                "storeSubscriptionType",
                response.data.subscription
              );
              this.$store.commit("storeUserID", response.data.user_id);

              this.$store.commit(
                "storeCustomerID",
                response.data.stripe_customer_id
              );
              this.toggleLoginModal();
              this.toggleMobileMenu();
              window.location = window.location.origin + "/predictions";
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          if (error.response.data) {
            this.submitting = false;
            this.error =
              "Invalid username or password. Please note, email addresses are case sensitive.";
            // this.error = error.response.data.body
            //   ? error.response.data.body
            //   : error.response.data;
          }
        });
    },
    async handleLogout() {
      let auth = {
        idToken: this.$store.getters.accessTokens.id_token,
      };
      let data = {
        accessToken: this.$store.getters.accessTokens.access_token,
      };
      this.loggingOut = true;
      API.post("/user/logout", auth, data)
        .then(() => {
          this.$store.commit("storeAccessTokens", {});
          this.$store.commit("storeUserID", false);
          this.$store.commit("storeCustomerID", false);
          this.$store.commit("storeSubscriptionType", false);
          this.$router.push("/");
          setTimeout(() => {
            location.reload();
          }, 200);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    decideURL() {
      return this.subscriptionType ? "/predictions" : "/";
    },
    subscriptionType() {
      return this.$store.getters.subscriptionType;
    },
    stripeProductCode() {
      return this.$store.getters.stripeProductCode;
    },
  },
};
</script>

<style>
.ms-style .ms-portal-exit.ms-portal-exit {
  display: flex !important;
  padding: 0 !important;
}
</style>
