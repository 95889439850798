const FAQs = [
  {
    title: "What is Numbers Game?",
    content:
      "Numbers Game is a website that presents football statistics, analysis, predictions, and odds to subscribers. Our predictions are based on a machine learning algorithm that analyses historical data to predict the outcome of football matches.",
  },
  {
    title: "How do I place a bet?",
    content:
      "Numbers Game is not a bookmaker and does not facilitate betting in any capacity - no bets are ever placed with Numbers Game or on it’s website. Bets should be placed responsibly and only with properly licensed bookmakers.",
  },
  {
    title: "Why would I pay for stats when i can get them free elsewhere?",
    content:
      "It is true that lots of football data, including much of that on Numbers Game, can be found for free elsewhere on the internet. However, we at Numbers Game have consolidated stats from many different sources into our super easy to read MatchView™ format, so you can spend less time researching numbers and more time doing what you love. Aside from this we also provide the most accurate AI powered football predictions on the market.",
  },
  {
    title: "Should I go off the prediction percentage or the odds?",
    content:
      "Numbers Game makes no specific recommendation and can be used to supplement a variety of strategies. The percentage prediction offered is based on a machine learning algorithm and you may choose to oppose the prediction and opt to back the pick with high value odds, which are highlighted for your convenience. This ultimately depends on the user and how you wish to leverage Numbers Game.",
  },
  {
    title: "Should I pick anything highlighted in green?",
    content:
      "Not necessarily. The highest percentage is highlighted in green so you can quickly identify which team is the favourite, according to machine learning, heading into the match. The odds highlighted in green represent where the best odds available are longer than what the machine learning algorithm believes to be fair odds, therefore representing high value odds that are potentially longer than they should be. You can use this information to create or support a long term strategy of your own.",
  },
  {
    title: "What is Numbers Game’s win percentage for the predictions?",
    content:
      "The model accuracy typically ranges between 50% and 75%, depending on the competition and timeframe analysed. A full CSV export of historical results is available on request.",
  },
  {
    title: "What payment methods do you accept?",
    content:
      "We accept debit and credit cards as well as Google and ApplePay. We hope to add more and more payment methods in the future to make Numbers Game as accessible as possible for our users across the world.",
  },
  {
    title: "How do I sign up?",
    content:
      "Click on the blue ‘SIGNUP’ button in the top right corner of the website. From here you can select a package and will only be asked to enter an email and a password before confirming. This means you can sign up in seconds and start creating or implementing your own strategy, but make sure you spell your email address and password correctly.",
  },
  // {
  //   title: "What’s the difference between the free and paid subscription?",
  //   content:
  //     "Numbers Game free subscription gives you access to 3 randomly selected games per day including the prediction, data and odds. With a paid subscription, you unlock unlimited access to every game across Europe’s top leagues and the MLS, giving you even more opportunities to start making money!",
  // },
  {
    title: "What are today’s predictions?",
    content:
      "To get access to predictions for today’s games, you will need to subscribe. Subscribers get access to every game happening across more than 50 competitions world wide.",
  },
];

export default FAQs;
