<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M13 7l5 5m0 0l-5 5m5-5H6"
    />
  </svg>
</template>

<script>
export default {
  name: "Arrow",
};
</script>
